@import url('https://fonts.googleapis.com/css2?family=Rubik:ital,wght@0,300..900;1,300..900&display=swap');


*{
    font-family: 'Rubik', sans-serif;
    margin:0;
    padding: 0;
    width:auto;
    height:auto;
    /* scrollbar-width: none; */
}
.button:hover{
    filter:drop-shadow(0 0 20px #21D6AA);
    height: 200px;
    width:200px;
}
.background-images {
    background-image: url("/public/images/Green-Rectangle.svg");
    background-repeat: no-repeat;
}  
.mainnn{
    position: relative;
}
.container{
    height:100vh;
    width: 100%;
    display: flex;
    align-items: center;
   flex-direction: column;
   text-align: center;
   background: url("/public/images/Home-Page-Header-Animation_2.gif");
   background-size: cover;
  background-position: center;
  position: sticky;
  top: 0;
  background-color: #f0f0f0; /* Adjust background color as needed */
  padding: 20px;
}
.cover-item {
    position: relative; /* Relative positioning for z-index to work */
    z-index: 1; /* Ensure this is higher than sticky-item */
    background-color: #ffffff; /* Adjust background color as needed */
    margin-bottom: -80px;
    background: linear-gradient(to bottom, rgba(71, 64, 64, 0) 0%, rgba(255, 255, 255, 1) 12%);
    padding-top: 200px;
}
.gradient{
    position:fixed;
    top: 86%;
    bottom: 0;
    z-index: 999;   
    width: 100%;
}
header {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    z-index: 999;
    left: 50%;
    transform: translateX(-50%);
    padding: 15px 40px;
    background-color: transparent;
    transition: background-color 0.5s ease;
  }
  .scrolled {
    background-color: white;
  }
  .logo {
    display: block;
    margin: 0 auto; 
}

.buttonimage{
    position: absolute;
    bottom: -13%;
    left:50%;
    transform: translateX(-50%);
    z-index: 999; 
    display: flex;
    justify-content: center;
    text-align: center; 
    align-items: center; 
    cursor: pointer; 
    transition: opacity 0.3s ease;
}
.elevate{
    line-height: 45px;
    margin-top: 80px;
    font-size: 30px;
    font-weight: bold;
    color: #1F2E39;
    letter-spacing: 3px;
}
.based{
    line-height: 25px;
    margin-top: 10px;
    font-size: 15px;
    color:black;
    letter-spacing: 0px;
    color: #1F2E39;
    opacity: 1;
}
.get{
    height: 100vh;
    width: 100%;
    font-size:35px;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    background-image: url("/public/images/Second-Section-Animation.gif");
    background-repeat: no-repeat;
    background-position: center;
    background-size: contain;
    line-height: 52px;
    font-weight:bold;
    background-color: #FFFFFF00;
}
span:hover{
    background-image: url("/public/images/Real-Time.gif");
    background-repeat: no-repeat;
    background-size:50px;
    background-position:130px -40px;
    background-origin: content-box;
    padding-top:100px;
}
.underline{
    position: relative;
}
.underline::after{
    content:'';
    position:absolute;
    width:100%;
    border-top:solid 3px #5891D6;
    left:0;
    bottom: -15px;
    border-radius: 50%;
    height: 9px;
}
.underline:hover::after{
    animation:move 0.5s ease-in;
}
@keyframes move{
    from{
        width:0%;
    }
    to{
        width:100%
    }
}


/*progress bar*/
/* StepProgressBar.css */

.progress-bar {
    display: flex;
    justify-content:center;
}

.step {
    width: 100px;
    height: 17px;
    border: 2px solid #C4F5EF;
    background-color:#C4F5EF;
    border-radius: 5%;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    cursor: pointer;
    margin-right: 50px;
    font-size: 15px;
    font-weight: bold;
}

.step .active {
    background-color:#58D68D;
    color: #fff;
    border-color: #58D68D;
}
.row{
    display:flex;
    flex-direction: row;
    gap: 100px;
}
.column{
    margin-top: 30px;
}
.conteinr{
    display: flex;
    padding: 30px 5px;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    background:transparent radial-gradient(closest-side at 50% 50%, #FFFFFF 0%, #E1FFF8 75%, #E1FFF8 100%) 0% 0% no-repeat padding-box;
    margin-top:20px;
    border-radius:5px;
    margin-right: 80px;
    margin-left: 80px;
    margin-bottom: 250px;
}
.TabPanel{
    opacity: 1;
    transition: opacity 3s ease-in-out;
}
.TabPanel[area-hidden='true']{
    opacity: 0;
}
.smart{
    font-size: 30px;
    letter-spacing: 3px;
    font-weight:bold;
    color:#1F2E39;
}
.underp{
    font-size: 15px;
    letter-spacing: 0;
    color:#1F2E39;
}
.content {
    padding: 20px;
    border-radius: 4px;
    margin-top: 10px;
    display: none; /* Initially hide all content */
}
.content.active {
    display: block; /* Display content when active */
    align-items: center;
}
.costt{
    font-size:20px;
    font-weight: bold;
    color:#1F2E39;
}

/*stepper*/

.how{
    font-size:30px;
    font-weight:bold;
    letter-spacing: 3px;
    text-align: center;
    margin-bottom: 40px;
    color:#1F2E39;
    padding-top:40px;
}
.root
{
    display:flex;
    flex-direction: row;
    justify-content: center;
    row-gap:100px;
    margin-bottom: 150px;
}
.imagee{
    display:flex;
    justify-content: center;
    width:38%;
    margin-left:150px;
}
.make{
    margin-top:40px;
    color:#5A93ED;
    text-shadow: 0px 0px 5px #FFFFFFB3;
    font: normal normal medium 15px/23px Rubik;
}
.tech{
    font: normal normal normal 15px/23px Rubik;
    text-align: justify;
    color:#1F2E39;
    letter-spacing: 0;
    margin-top:10px;
}
.stacky{
    background-image:url('/public/images/Dots Left.svg'), url('/public/images/Dots Right.svg'); 
    background-repeat:no-repeat, no-repeat;
    background-position: left top, right top;
    background-size: 100vh ,100vh;
    position:relative;
    z-index:9;
}
.stakoo{
    position:relative;
    z-index: 99;
}
.customtab{
    width:100px;
    height:23px;
}

/* media query */

@media screen and (max-width: 389px){
    .buttonimage{
        position: fixed;
        bottom: -30px;
    }
    .boddy{
        box-sizing: border-box;
    }
    br{
        display:none;
    }
    .logo{
        margin-top: 25px;
    }
    .container{
        height:100vh;
    }
    .elevate{
        font-size:23px;
        font-weight:700;
        letter-spacing: 1px;
        margin-top: 100px;
        line-height: 35px;
    }
    .based{
        display:none;
    }
    .gradient{
        object-fit:cover;
        height: 17vh;
        top:83%;
    }
    .get{
        height:60vh;
        font-size:23px;
        padding-right: 5px;
        padding-left:5px;
        line-height: 37px;
        background-image: none;
    }
    .cover-item{
        padding-top:370px;
    }
    .wavy{
        object-fit:cover;
        height: 17vh;
    }
    /*stepper*/
    .how{
        font-size: 24px;
        padding-top: 30px;
    }
    .root
    {
        flex-direction: column;
        justify-content: center;
        row-gap:0;
        margin-bottom: 100px;
        margin-right:20px;
        margin-left:20px;
    }
    .imagee{
        width:100%;
        margin-left:0;
        margin-top: 40px;
    }
    .stacky{
        background-size: 45vh ,45vh;
    }
    /*button*/

    .conteinr{
        flex-direction: column;
        align-items: center;
        background-repeat: no-repeat;
        background-position: center;
        background-size:contain;
        margin-top:30px;
        background:#E1FFF8;
        margin-right: 0px;
        margin-left:0px;
        padding: 20px 10px;
        margin-bottom: 300px;
    }
    .row{
        flex-direction: column;
        gap:0;
    }
    .smart{
        font-size:20px;
        text-align: center;
        font-weight: 700;
        letter-spacing: 1.5px;
    }
    .underp{
        margin-top:30px;
    }
    .customtab{
        width:20px;
        height:24px;
    }
}
@media screen and (min-width: 390px) and (max-width: 767px){
    .boddy{
        box-sizing: border-box;
    }
    .buttonimage{
        position: fixed;
        bottom: 90px;
    }
    br{
        display:none;
    }
    .logo{
        margin-top: 25px;
    }
    .container{
        height:100vh;
    }
    .elevate{
        font-size:26px;
        font-weight:700;
        letter-spacing: 1px;
        margin-top: 140px;
        line-height: 37px;
    }
    .based{
        display:none;
    }
    .gradient{
        object-fit:cover;
        height: 15vh;
        top:76%;
    }
    .get{
        height:60vh;
        font-size:26px;
        padding-right: 20px;
        padding-left:20px;
        line-height: 37px;
        background-image: none;
    }
    .cover-item{
        padding-top:350px;
    }
    .wavy{
        object-fit:cover;
        height: 17vh;
    }
    /*stepper*/
    .how{
        font-size: 24px;
        padding-top: 30px;
    }
    .root
    {
        flex-direction: column;
        justify-content: center;
        row-gap:0;
        margin-bottom: 100px;
        margin-right:40px;
        margin-left:40px;
    }
    .imagee{
        width:100%;
        margin-left:0;
        margin-top: 40px;
    }
    .stacky{
        background-size: 45vh ,45vh;
    }
    /*button*/

    .conteinr{
        padding: 20px;
        flex-direction: column;
        align-items: center;
        background-repeat: no-repeat;
        background-position: center;
        background-size:contain;
        margin-top:30px;
        background:#E1FFF8;
        margin-right: 0px;
        margin-left:0px;
        padding: 20px 10px;
        margin-bottom: 390px;
    }
    .row{
        flex-direction: column;
        gap:0;
    }
    .smart{
        font-size:20px;
        text-align: center;
        font-weight: 700;
        letter-spacing: 1.5px;
    }
    .underp{
        margin-top:30px;
    }
    .customtab{
        width:20px;
        height:24px;
    }
}

/* Tablet media query */

@media screen and (min-width: 768px) and (max-width: 1023px) {

.boddy{
    box-sizing: border-box;
}

.buttonimage{
    position: fixed;
    bottom: 90px;
    left:50%;
    transform: translateX(-50%);
    z-index: 999; 
    display: flex;
    justify-content: center;
    text-align: center; 
    align-items: center; 
    cursor: pointer; 
    transition: opacity 0.3s ease;
}

br{
    display:none;
}
.logo{
    margin-top: 25px;
}
.container{
    height:100vh;
}
.elevate{
    font-size:30px;
    font-weight:700;
    letter-spacing: 1px;
    margin-top: 190px;
}
.based{
    display:none;
}
.gradient{
    object-fit:cover;
    height: 17vh;
    top:75%;
}
.get{
    height:60vh;
    font-size:30px;
    padding-right: 20px;
    padding-left:20px;
    line-height: 45px;
    background-image: none;
}
.cover-item{
    padding-top:350px;
}
.wavy{
    object-fit:cover;
    height: 17vh;
}
/*stepper*/
.how{
    font-size: 24px;
    padding-top: 30px;
}
.root
{
    flex-direction: column;
    justify-content: center;
    row-gap:0;
    margin-bottom: 100px;
    margin-right:40px;
    margin-left:40px;
}
.imagee{
    width:100%;
    margin-left:0;
    margin-top: 40px;
}
.stacky{
    background-size: 60vh ,60vh;
}
/*button*/

.conteinr{
    padding: 20px;
    flex-direction: column;
    align-items: center;
    background-repeat: no-repeat;
    background-position: center;
    background-size:contain;
    margin-top:30px;
    background:#E1FFF8;
    margin-right: 0px;
    margin-left:0px;
    padding: 20px 10px;
    margin-bottom: 490px;
}
.row{
    flex-direction: column;
    gap:0;
}
.smart{
    font-size:20px;
    text-align: center;
    font-weight: 700;
    letter-spacing: 1.5px;
}
.underp{
    margin-top:30px;
}
.customtab{
    width:20px;
    height:24px;
}
}


@media screen and (min-width: 1024px) and (max-width: 1150px) {

    .buttonimage{
        position: fixed;
        bottom: 200px;
        left:50%;
        transform: translateX(-50%);
        z-index: 999; 
        display: flex;
        justify-content: center;
        text-align: center; 
        align-items: center; 
        cursor: pointer; 
        transition: opacity 0.3s ease;
    }
    .underp br{
        display: none;
    }
    .elevate{
        margin-top: 200px;
        font-size: 35px;
    }
    .based{
        margin-top: 50px;
        font-size: 20px;
    }
    .conteinr{
        margin-bottom: 300px;
    }
}





@media screen and (min-width: 1150px) and (max-width: 1241px) {

    .buttonimage{
        position: sticky;
        bottom: 80px;
        left:50%;
        transform: translateX(-50%);
        z-index: 999; 
        display: flex;
        justify-content: center;
        text-align: center; 
        align-items: center; 
        cursor: pointer; 
        transition: opacity 0.3s ease;
    }
}

@media screen and (min-width: 1242px) and (max-width: 1400px) {

    .buttonimage{
        position: sticky;
        bottom: 34px;
        left:50%;
        transform: translateX(-50%);
        z-index: 999; 
        display: flex;
        justify-content: center;
        text-align: center; 
        align-items: center; 
        cursor: pointer; 
        transition: opacity 0.3s ease;
    }
}

@media screen and (min-width: 1400px) and (max-width: 1600px) {

    .buttonimage{
        position: sticky;
        bottom: 2px;
        left:50%;
        transform: translateX(-50%);
        z-index: 999; 
        display: flex;
        justify-content: center;
        text-align: center; 
        align-items: center; 
        cursor: pointer; 
        transition: opacity 0.3s ease;
    }
}


@media screen and (min-width: 1601px) and (max-width: 1800px) {

    .buttonimage{
        position: sticky;
        bottom: -30px;
        left:50%;
        transform: translateX(-50%);
        z-index: 999; 
        display: flex;
        justify-content: center;
        text-align: center; 
        align-items: center; 
        cursor: pointer; 
        transition: opacity 0.3s ease;
    }
}

@media screen and (min-width: 1801px) and (max-width: 2000px) {

    .buttonimage{
        position: sticky;
        bottom: -90px;
        left:50%;
        transform: translateX(-50%);
        z-index: 999; 
        display: flex;
        justify-content: center;
        text-align: center; 
        align-items: center; 
        cursor: pointer; 
        transition: opacity 0.3s ease;
    }
}


@media screen and (min-width: 2001px) and (max-width: 2200px) {

.buttonimage{
    position: absolute;
    bottom: -13%;
    left:50%;
    transform: translateX(-50%);
    z-index: 999; 
    display: flex;
    justify-content: center;
    text-align: center; 
    align-items: center; 
    cursor: pointer; 
    transition: opacity 0.3s ease;
}

}

@media screen and (min-width: 2201px) and (max-width: 2400px) {

    .buttonimage{
        position: absolute;
        bottom: -18%;
        left:50%;
        transform: translateX(-50%);
        z-index: 999; 
        display: flex;
        justify-content: center;
        text-align: center; 
        align-items: center; 
        cursor: pointer; 
        transition: opacity 0.3s ease;
    }
    
    }


    @media screen and (min-width: 2401px) and (max-width: 2600px) {

        .buttonimage{
            position: fixed;
            bottom: -18%;
            left:50%;
            transform: translateX(-50%);
            z-index: 999; 
            display: flex;
            justify-content: center;
            text-align: center; 
            align-items: center; 
            cursor: pointer; 
            transition: opacity 0.3s ease;
        }
        
        }